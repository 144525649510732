
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
import { Item } from '@/store/models/ItemModel';
import HiRezImg from '@/components/HiRezImg.vue';
import ItemService from '@/services/item-service';
import { Stage } from 'konva/types/Stage';
import { Layer } from 'node_modules/konva/types/Layer';
import { Image } from 'konva/types/shapes/Image';
import Konva from 'konva';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { BarcodeGenModel } from '@/store/models/RequestModels';

interface KonvaLayer extends Vue {
  getNode(): Layer;
}
interface KonvaStage extends Vue {
  getStage(): Stage;
}
interface KonvaImage extends Vue {
  getNode(): Image;
}
declare global {
  interface Window {
    Image: any;
  }
}
@Component({
  components: { DefaultLayout, HiRezImg }
})
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
    barcode: KonvaImage;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  configKonva: any = {
    width: window.innerWidth,
    height: window.innerHeight
  };
  configCircle: any = {
    x: 100,
    y: 100,
    radius: 70,
    fill: 'red',
    stroke: 'black',
    strokeWidth: 4,
    draggable: true
  };
  item: Item = new Item();
  image: any = null;
  isSavingBarcodePlacement: boolean = false;
  barcode: {
    image: HTMLImageElement;
    draggable: boolean;
    x: number;
    y: number;
  } | null = null;
  barcodePosition: { x: number; y: number } = { x: 0, y: 0 };
  zoomMultiplier: number = 1;
  barcodeHeight: number = 0.65;
  barcodeWidth: number = 0.65;
  isFormValid: boolean = true;
  trimline: string = '';
  safetyline: string = '';
  barcodeTypes: Array<{ Id: number; Name: string }> = [
    { Id: 1, Name: 'Data Matrix' },
    { Id: 4, Name: 'Qr Code' }
  ];
  selectedBarcodeType: { Id: number; Name: string } = { Id: 4, Name: 'Qr Code' };
  buffer: number = 0.065;

  get barcodeYPosition() {
    return this.barcodePosition.y - this.buffer;
  }
  get barcodeXPosition() {
    return this.barcodePosition.x - this.buffer;
  }

  /* Utility Functions */
  async GetItem() {
    const response = await ItemService.GetItemDetails(this.$route.params.itemId);
    this.item = new Item(response.data);

    const thumbnailResponse = await ItemService.GetItemThumbnail(
      2000,
      2000,
      this.item.Id,
      this.item.Version
    );
    if (this.item.BarcodeTypeId) {
      this.selectedBarcodeType = this.barcodeTypes.find(
        b => b.Id == this.item.BarcodeTypeId
      );
    }
    this.setupImageAndBarcode(thumbnailResponse.data);
    if (this.item.BarcodePlacementId) await this.GetBarcodePlacement();
  }
  async GetBarcodePlacement() {
    const { data } = await ItemService.GetBarcodePlacement(
      this.item.Id,
      this.item.BarcodePlacementId
    );
    this.barcodePosition.x = data.XPosition;
    this.barcodePosition.y = data.YPosition;
    this.repositionBarcode();
  }
  async GetDieLines() {
    const response = await ItemService.GetDieLines(this.$route.params.itemId);
    console.log(response.data);
    if (response.data) {
      this.trimline = response.data.SVGTrimLineRaster;
      this.safetyline = response.data.SVGSafetyLineRaster;
    }
  }
  setBarcode() {
    switch (this.selectedBarcodeType.Name) {
      case 'Data Matrix': {
        const barcode = new window.Image();
        barcode.src = require('../assets/datamatrix.svg');
        barcode.height = this.inchesToPixels(this.barcodeHeight);
        barcode.width = this.inchesToPixels(this.barcodeWidth);
        barcode.onload = () => {
          this.barcode = { image: barcode, draggable: true, x: 0, y: 0 };
        };
        return;
      }
      case 'Qr Code': {
        const barcode = new window.Image();
        barcode.src = require('../assets/QRm.svg');
        barcode.height = this.inchesToPixels(this.barcodeHeight);
        barcode.width = this.inchesToPixels(this.barcodeWidth);
        barcode.onload = () => {
          this.barcode = { image: barcode, draggable: true, x: 0, y: 0 };
        };
        return;
      }
    }
  }
  setupImageAndBarcode(imageSrc: string) {
    const image = new window.Image();
    image.onload = () => {
      this.image = image;
      this.configKonva.width = image.width;
      this.configKonva.height = image.height;
    };
    image.src = imageSrc;
    image.height = this.inchesToPixels(this.item.Die.BleedHeight);
    image.width = this.inchesToPixels(this.item.Die.BleedWidth);
    let maxWidth = window.innerWidth > 2200 ? 1500 : 1200;
    if (image.width > maxWidth) {
      this.zoomMultiplier = 1 / (image.width / maxWidth);
    } else if (image.width * 2 < maxWidth && image.height * 2 < window.innerHeight) {
      this.zoomMultiplier = 1;
    }
    image.height = image.height * this.zoomMultiplier;
    image.width = image.width * this.zoomMultiplier;
    this.setBarcode();
  }
  drag(response: any) {
    console.log(window.devicePixelRatio);
    const position = this.$refs.barcode.getNode().getAbsolutePosition();
    this.barcodePosition.x = parseFloat(
      (this.pixelsToInches(position.x) + 0.0655).toFixed(2)
    );
    this.barcodePosition.y = parseFloat(
      (this.pixelsToInches(position.y) + this.buffer).toFixed(2)
    );
  }
  repositionBarcode() {
    let barcode = this.$refs.barcode.getNode();
    barcode.setPosition({
      x: this.inchesToPixels(this.barcodeXPosition),
      y: this.inchesToPixels(this.barcodeYPosition)
    });
    barcode.getStage()!.draw();
  }
  pixelsToInches(size: number) {
    return size / 96 / this.zoomMultiplier;
  }
  inchesToPixels(size: number) {
    return size * 96 * this.zoomMultiplier;
  }
  async saveBarcodePlacement() {
    this.isSavingBarcodePlacement = true;
    try {
      let model: BarcodeGenModel = {
        WidthInches: 0.5,
        HeightInches: 0.5,
        Top: this.barcodePosition.y,
        Left: this.barcodePosition.x,
        ItemId: this.item.Id,
        Id: this.item.BarcodePlacementId,
        BarcodeTypeId: this.selectedBarcodeType.Id
      };
      await ItemService.SaveBarcodePosition(model);
      this.$store.dispatch('items/refreshItem', this.item.Id);
      SetSnackBar('Barcode placement saved successfully');
      this.$router.push({ name: 'ManageItems' });
    } catch (err) {
      SetSnackBar(
        'There was an error saving your barcode position. Please contact techsupport@just1label.com to resolve your issue'
      );
    }
    this.isSavingBarcodePlacement = false;
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    this.GetItem();
    this.GetDieLines();
  }
  /* Created */
  /* Emmited Functions */
}
