var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DefaultLayout',{ref:"defaultActions",scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('v-container',{staticClass:"barcode-container",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-row',{staticClass:"approval-title",attrs:{"justify":"center","no-gutters":""}},[_vm._v("Place your barcode")])],1),_c('v-row',{attrs:{"justify":"center","no-gutters":""}}),_c('v-row',{staticClass:"roboto-regular mb-4",staticStyle:{"color":"#999999"},attrs:{"justify":"center","no-gutters":""}},[_c('span',[_vm._v(" Drag the barcode into the position you want it to be on the label. ")])]),_c('v-col',{staticClass:"image-margin",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-card',{staticClass:"background-color",attrs:{"flat":"","tile":""}},[_c('v-stage',{ref:"stage",attrs:{"id":"stage","config":_vm.configKonva}},[_c('v-layer',[_c('div',{staticClass:"img-overlay-wrap"},[_c('v-image',{attrs:{"config":{ image: _vm.image }}}),_c('v-image',{ref:"barcode",attrs:{"config":_vm.barcode},on:{"dragend":_vm.drag}}),(_vm.trimline)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.trimline)}}):_vm._e()],1)])],1)],1)],1)],1),_c('v-row',{staticClass:"barcode-metrics",attrs:{"no-gutters":"","justify":"center"}},[_c('v-card',{attrs:{"width":"300px"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-card-title',[_vm._v("Barcode Metrics")])],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[(_vm.item.Die && _vm.item.Die.Width)?_c('v-text-field',{staticClass:"text-box-with-commas",attrs:{"outlined":"","label":"Horizontal Location","persistent-hint":"","hint":`Max width ${parseFloat(_vm.item.Die.Width) - 0.5}`,"rules":[
                  () => _vm.barcodePosition.x >= 0 || 'Invalid input',
                  () =>
                    _vm.barcodePosition.x <= _vm.item.Die.Width - 0.5 ||
                    `Cannot exceed ${_vm.item.Die.Width - 0.5}`
                ]},on:{"change":_vm.repositionBarcode},model:{value:(_vm.barcodePosition.x),callback:function ($$v) {_vm.$set(_vm.barcodePosition, "x", $$v)},expression:"barcodePosition.x"}}):_vm._e(),(_vm.item.Die && _vm.item.Die.Height)?_c('v-text-field',{staticClass:"text-box-with-commas pl-1",attrs:{"outlined":"","label":"Vertical Location","persistent-hint":"","hint":`Max height ${parseFloat(_vm.item.Die.Height) - 0.5}`,"rules":[
                  () => _vm.barcodePosition.y >= 0 || `Invalid input`,
                  () =>
                    _vm.barcodePosition.y <= _vm.item.Die.Height - 0.5 ||
                    `Cannot exceed ${_vm.item.Die.Height - 0.5}`
                ]},on:{"change":_vm.repositionBarcode},model:{value:(_vm.barcodePosition.y),callback:function ($$v) {_vm.$set(_vm.barcodePosition, "y", $$v)},expression:"barcodePosition.y"}}):_vm._e(),_c('v-select',{attrs:{"outlined":"","label":"Barcode type","item-text":"Name","return-object":"","items":_vm.barcodeTypes},on:{"change":_vm.setBarcode},model:{value:(_vm.selectedBarcodeType),callback:function ($$v) {_vm.selectedBarcodeType=$$v},expression:"selectedBarcodeType"}}),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-btn',{staticClass:"v-btn__bold-blue",attrs:{"block":"","loading":_vm.isSavingBarcodePlacement,"disabled":!_vm.isFormValid},on:{"click":function($event){return _vm.saveBarcodePlacement()}}},[_vm._v("Save")])],1)],1)],1)],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }